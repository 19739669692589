import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import shallowEqual from 'shallowequal';

import type { MenuQueryOpts } from '../queries/types';
import { useMenuPickerQuery } from '../queries/use-menu-picker-query';
import { useMenuQueryOpts } from '../queries/use-menu-query-opts';
import type { MenuPickerData } from '../types';

export type MenuPickerContext = {
  slug: string;
  query: ReturnType<typeof useMenuPickerQuery>;
  // Derived
  displayName: string;
  priceCents: number;

  // Instructions
  instructions: string;
  setInstructions: Dispatch<SetStateAction<string>>;

  // PickerAspectOption
  selectedPickerAspectOptionId: string;
  setSelectedPickerAspectOptionId: Dispatch<SetStateAction<string>>;
  selectedPickerAspectOption: null | MenuPickerData['pickerAspect']['options'][number];

  // Customizations
  // { [customizationOptionKey]: optionOptionKey | { [optionOptionKey]: quantity } }
  customizationSelections: Record<string, string | Record<string, number>>;
  setCustomizationSelections: Dispatch<
    SetStateAction<Record<string, string | Record<string, number>>>
  >;
  defaultCustomizationSelections: Record<string, string | Record<string, number>>;
  draftCustomizationSelections: Record<string, string | Record<string, number>>;
  setDraftCustomizationSelections: Dispatch<
    SetStateAction<Record<string, string | Record<string, number>>>
  >;

  // Validation
  showErrors: boolean;
  setShowErrors: (shouldShowErrors: boolean) => void;

  // Error messages derived
  // { [customizationOptionKey]: message }
  errorMessages: Record<string, string>;

  hasUnsavedCustomizationSelections: boolean;
};

const menuPickerContext = createContext<MenuPickerContext | null>(null);

export type MenuPickerProviderProps = {
  children?: ReactNode;
  slug: string;
  menuQueryOpts?: Partial<MenuQueryOpts>;
};

const MenuPickerProviderState = (props: MenuPickerProviderProps) => {
  const slug = props.slug;

  const query = useMenuPickerQuery({
    slug,
    menuQueryOpts: useMenuQueryOpts(props.menuQueryOpts),
  });

  const [instructions, setInstructions] = useState('');

  // PickerAspectOption
  const [_selectedPickerAspectOptionId, setSelectedPickerAspectOptionId] = useState('');
  const defaultPickerAspectOptionId = query.data?.pickerAspect.defaultOptionKey;
  const selectedPickerAspectOptionId = _selectedPickerAspectOptionId || defaultPickerAspectOptionId;
  const selectedPickerAspectOption = useMemo(
    () =>
      query.data?.pickerAspect.options.find(
        option => option.key === selectedPickerAspectOptionId
      ) ?? null,
    [query.data?.pickerAspect.options, selectedPickerAspectOptionId]
  );

  // Customizations
  const defaultCustomizationSelections: MenuPickerContext['defaultCustomizationSelections'] =
    useMemo(() => {
      const defaultSelections: MenuPickerContext['defaultCustomizationSelections'] = {};

      forEachCustomizationOptionInPickerAspectOption(
        selectedPickerAspectOption,
        ({ customizationOption }) => {
          if (customizationOption.displayType === 'CHECKBOX') {
            const onOption = customizationOption.options.find(o => o.multiplier > 0);
            const offOption = customizationOption.options.find(o => o.multiplier === 0);
            const isOnOptionDefault = customizationOption.defaultOptionKey === onOption?.key;

            // Make sure if customizationOption is not available (out of stock) set it to off.
            if (!onOption.isAvailable && isOnOptionDefault) {
              defaultSelections[customizationOption.key] = offOption.key;
              return;
            }
          }

          if (customizationOption.displayType === 'SELECT') {
            const defaultOption = customizationOption.options.find(
              o => o.key === customizationOption.defaultOptionKey
            );
            if (!defaultOption.isAvailable) {
              const firstAvailableOption = customizationOption.options.find(o => o.isAvailable);
              if (firstAvailableOption) {
                defaultSelections[customizationOption.key] = firstAvailableOption.key;
                return;
              } else {
                // @TODO: Decide how to handle cases where no options are available.
                // Should we remove this customization option from `defaultSelections`?
              }
            }
          }

          if (customizationOption.displayType === 'STEPPER') {
            // Check if any option is available
            const hasAvailableOption = customizationOption.options.some(
              o => o.isAvailable && o.prefix !== 'No'
            );

            // If no available options exist, disable the item
            if (!hasAvailableOption) {
              const noOption = customizationOption.options.find(o => o.prefix === 'No');

              if (noOption) {
                defaultSelections[customizationOption.key] = noOption.key;
                return;
              }
            }

            // Check default value, in case it is unavailable, set the first value.
            const defaultOption = customizationOption.options.find(
              o => o.key === customizationOption.defaultOptionKey
            );
            if (!defaultOption.isAvailable) {
              const availableOption = customizationOption.options.find(
                o => o.isAvailable && o.prefix !== 'No'
              );
              defaultSelections[customizationOption.key] = availableOption.key;
              return;
            }
          }

          if (customizationOption.displayType === 'QUANTITY') {
            // Handle not active options
            const hasNotAvailableOptions = customizationOption.options.some(o => !o.isAvailable);

            if (hasNotAvailableOptions) {
              const notAvailableOptions = customizationOption.options.filter(o => !o.isAvailable);

              for (const options of notAvailableOptions) {
                defaultSelections[customizationOption.key] = {
                  [options.key]: 0,
                };
              }
            }

            // Handle default option
            const defaultOption = customizationOption.options.find(
              o => o.key === customizationOption.defaultOptionKey
            );

            // if no default option || it is not available, skip and let the user chose.
            if (!defaultOption || !defaultOption.isAvailable) {
              return;
            }

            // In case we have a default option, set the min minimum amount.
            if (customizationOption.minAmount && customizationOption.minAmount > 1) {
              // Default customizationOption to minimum quantity of default option
              defaultSelections[customizationOption.key] = {
                [customizationOption.defaultOptionKey]: customizationOption.minAmount,
              };
              return;
            }
          }

          if (customizationOption.displayType === 'RADIO') {
            // Default option can not be unavailable
            const defaultOption = customizationOption.options.find(
              o => o.key === customizationOption.defaultOptionKey
            );

            // if defaultOption is not available set the first available option as default
            if (!defaultOption.isAvailable) {
              const availableOption = customizationOption.options.find(
                o => o.key !== customizationOption.defaultOptionKey && o.isAvailable
              );
              defaultSelections[customizationOption.key] = availableOption.key;
              return;
            }
          }

          // If no other scenario is found, remove it.
          defaultSelections[customizationOption.key] = customizationOption.defaultOptionKey;
        }
      );

      return defaultSelections;
    }, [selectedPickerAspectOption]);

  const [customizationSelections, setCustomizationSelections] = useState<
    MenuPickerContext['customizationSelections']
  >({});
  const [draftCustomizationSelections, setDraftCustomizationSelections] = useState<
    MenuPickerContext['customizationSelections']
  >({});

  const handlePickerAspectSelection = useCallback(
    (pickerOptionId: string) => {
      setSelectedPickerAspectOptionId(pickerOptionId);

      // Reset customization selections
      setCustomizationSelections({});
    },
    [setSelectedPickerAspectOptionId, setCustomizationSelections]
  );

  // Price
  const priceCents = useMemo(() => {
    const itemBasePriceCents = selectedPickerAspectOption?.item?.basePriceCents ?? 0;

    // { [optionKey]: { [optionOptionKey]: upchargeCents } }
    const customizationOptionPriceMapping: Record<string, Record<string, number>> = {};
    forEachCustomizationOptionInPickerAspectOption(
      selectedPickerAspectOption,
      ({ customizationOption }) => {
        const keyToPriceMapping: Record<string, number> = {};

        for (const opt of customizationOption.options) {
          keyToPriceMapping[opt.key] = opt.upChargeCents;
        }

        customizationOptionPriceMapping[customizationOption.key] = keyToPriceMapping;
      }
    );

    const customizationsPriceCents = Object.entries(customizationSelections).reduce(
      (cents, [optionKey, optionOption]) => {
        if (typeof optionOption === 'string') {
          // optionOption is a key, not an object of quantities
          return cents + (customizationOptionPriceMapping?.[optionKey]?.[optionOption] ?? 0);
        }

        let optionOptionPriceCents = 0;
        for (const [optionOptionKey, qty] of Object.entries(optionOption)) {
          optionOptionPriceCents +=
            (customizationOptionPriceMapping?.[optionKey]?.[optionOptionKey] ?? 0) * qty;
        }

        return cents + optionOptionPriceCents;
      },
      0
    );

    return itemBasePriceCents + customizationsPriceCents;
  }, [selectedPickerAspectOption, customizationSelections]);

  // Validation
  const [showErrors, setShowErrors] = useState<MenuPickerContext['showErrors']>(false);
  const errorMessages = useMemo(() => {
    const errors: Record<string, string> = {};

    forEachCustomizationOptionInPickerAspectOption(
      selectedPickerAspectOption,
      ({ customizationOption }) => {
        const selection =
          customizationSelections[customizationOption.key] ??
          defaultCustomizationSelections[customizationOption.key];

        if (typeof selection === 'string') {
          return;
        }

        // Quantities
        const totalQuantity = Object.values(selection).reduce((acc, qty) => acc + qty, 0);
        if (
          (customizationOption.minAmount && totalQuantity < customizationOption.minAmount) ||
          (customizationOption.maxAmount && totalQuantity > customizationOption.maxAmount)
        ) {
          errors[customizationOption.key] = 'Required';
        }
      }
    );

    return errors;
  }, [customizationSelections, defaultCustomizationSelections, selectedPickerAspectOption]);

  const hasUnsavedCustomizationSelections = useMemo(
    () =>
      Object.entries(draftCustomizationSelections).some(
        ([id, selection]) =>
          !shallowEqual(
            selection,
            customizationSelections[id] ?? defaultCustomizationSelections[id]
          )
      ),
    [customizationSelections, defaultCustomizationSelections, draftCustomizationSelections]
  );

  // Context
  const value: MenuPickerContext = useMemo(() => {
    return {
      slug,
      query,

      // Stateful
      selectedPickerAspectOptionId,
      setSelectedPickerAspectOptionId: handlePickerAspectSelection,
      instructions,
      setInstructions,
      defaultCustomizationSelections,
      customizationSelections,
      setCustomizationSelections,
      draftCustomizationSelections,
      setDraftCustomizationSelections,
      showErrors,
      setShowErrors,

      // Derived
      selectedPickerAspectOption,
      displayName: query.data?.displayName ?? '',
      priceCents,
      errorMessages,
      hasUnsavedCustomizationSelections,
    };
  }, [
    query,
    slug,
    instructions,
    setInstructions,
    selectedPickerAspectOptionId,
    selectedPickerAspectOption,
    defaultCustomizationSelections,
    draftCustomizationSelections,
    customizationSelections,
    setCustomizationSelections,
    priceCents,
    handlePickerAspectSelection,
    showErrors,
    setShowErrors,
    errorMessages,
    hasUnsavedCustomizationSelections,
  ]);

  return <menuPickerContext.Provider value={value}>{props.children}</menuPickerContext.Provider>;
};

export const MenuPickerProvider = (props: MenuPickerProviderProps) => {
  // Reset menu picker provider state when slug changes
  return <MenuPickerProviderState {...props} key={props.slug} />;
};

export const useMenuPicker = (): MenuPickerContext => {
  const context = useContext(menuPickerContext);

  if (!context && __DEV__) {
    throw new Error(
      'useMenuPicker can only be called from within a descendant of MenuPickerProvider'
    );
  }

  return context;
};

function forEachCustomizationOptionInPickerAspectOption(
  pickerAspectOption: MenuPickerContext['selectedPickerAspectOption'],
  cb: ({
    customizationOption,
    displayGroup,
  }: {
    customizationOption: MenuPickerContext['selectedPickerAspectOption']['item']['customizations']['displayGroups'][number]['options'][number];
    displayGroup: MenuPickerContext['selectedPickerAspectOption']['item']['customizations']['displayGroups'][number];
  }) => void
) {
  for (const displayGroup of pickerAspectOption?.item?.customizations?.displayGroups ?? []) {
    for (const customizationOption of displayGroup?.options ?? []) {
      cb({ customizationOption, displayGroup });
    }
  }
}

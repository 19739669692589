import { Stack, router, useRootNavigationState } from 'expo-router';
import { useEffect } from 'react';

import { useMqSelect } from '@fhs/ui';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import { getScreenTitleHeader } from '../../../components/get-screen-title-header';

export const unstable_settings = {
  initialRouteName: 'index',
};

export default function OffersStackLayout() {
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);
  const rootNavigationState = useRootNavigationState();
  const enableSimplyOfferUi = useFlag(LaunchDarklyFlag.ENABLE_SIMPLY_OFFER_UI);
  useEffect(() => {
    const navigatorReady = rootNavigationState?.key != null;
    if (navigatorReady && !enableSimplyOfferUi) {
      router.replace(routes.loyaltyOfferList);
    }
  }, [enableSimplyOfferUi, rootNavigationState]);
  return (
    <Stack
      screenOptions={{
        header: getScreenTitleHeader({ cannotGoBackHref: '/v2/menu', showDivider: true }),
        headerShown: !isDesktop,
        title: 'Offers & Rewards',
      }}
    >
      <Stack.Screen name="index" />
      <Stack.Screen
        name="details/[offerId]"
        options={{
          presentation: 'containedTransparentModal',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="guide/[offerId]"
        options={{
          presentation: 'containedTransparentModal',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="all"
        options={{
          title: 'See All Offers',
        }}
      />
    </Stack>
  );
}

import { useLocalSearchParams } from 'expo-router';
import { useEffect, useRef } from 'react';
import { Platform, View } from 'react-native';
import Animated, {
  FadeIn,
  FadeOut,
  SlideInLeft,
  SlideInRight,
  SlideOutLeft,
  SlideOutRight,
} from 'react-native-reanimated';

import { ErrorCard } from '@fhs/ui';

import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';
import { useOfferDetailUi } from '../../queries/loyalty.queries';
import { useIsItemInCart, useOfferCart } from '../../state/offer-guide-cart-state';

import { Content } from './content';
import { ItemInCart } from './item-in-cart';
import { NotAvailable } from './not-available';
import { OfferGuideLoading } from './offer-guide-loading';
import { Summary } from './summary';

export const FadeView = Platform.select({
  native: ({ children, style = {} }) => (
    <Animated.View style={style} entering={FadeIn.duration(500)} exiting={FadeOut}>
      {children}
    </Animated.View>
  ),
  web: ({ children, style = {} }) => <View style={style}>{children}</View>,
}) as any;

const SlideView = Platform.select({
  native: ({ children, isGoingForward = true, skipEntering = false }) => (
    <Animated.View
      style={{ flex: 1 }}
      entering={skipEntering ? undefined : isGoingForward ? SlideInRight : SlideInLeft}
      exiting={isGoingForward ? SlideOutLeft : SlideOutRight}
    >
      {children}
    </Animated.View>
  ),
  web: ({ children }) => <View style={{ flex: 1 }}>{children}</View>,
}) as any;

export function ContentRenderer() {
  const { offerId } = useLocalSearchParams<{ offerId: string }>();
  const { isLoading, isError, refetch } = useOfferDetailUi(offerId);

  const { offerInfo, showSummary, isGoingForward } = useOfferCart();

  const isItemInCart = useIsItemInCart();

  const isFirstContentRender = useRef(true);

  const { loyaltyUserReady } = useLoyaltyLegacyStates();

  useEffect(() => {
    if (!isLoading && offerInfo) {
      isFirstContentRender.current = false;
    }
  }, [isLoading, offerInfo]);

  if (isLoading || !loyaltyUserReady) {
    return <OfferGuideLoading />;
  }

  if (isError) {
    return (
      <ErrorCard
        description="We ran into an issue loading the content of this page. Please try again."
        buttonTitle="Reload"
        buttonHandle={refetch}
      />
    );
  }

  if (!offerInfo?.isAvailable) {
    return <NotAvailable />;
  }

  if (isItemInCart) {
    return (
      <FadeView style={{ flex: 1 }}>
        <SlideView isGoingForward={isGoingForward} skipEntering={true}>
          <ItemInCart />
        </SlideView>
      </FadeView>
    );
  }

  if (showSummary) {
    return (
      <FadeView style={{ flex: 1 }}>
        <SlideView isGoingForward={isGoingForward}>
          <Summary />
        </SlideView>
      </FadeView>
    );
  }

  return <Content isFirstRender={isFirstContentRender.current} />;
}

export const backgroundHeight = 187;
export const backgroundHeightDesktop = `min(15vh, ${backgroundHeight}px)`;

export const productImageHeight = 244;
export const productImageHeightDesktop = 'min(40.4vh, 442.8px, 100%)';

export const productImageTrayOffset = 125;
export const productImageTrayOffsetDesktop = 'min(16vh, 142px)';

export const productImageContainerHeight =
  backgroundHeight + productImageHeight - productImageTrayOffset;
export const productImageContainerHeightDesktop = `calc(${backgroundHeightDesktop} + ${productImageHeightDesktop} - ${productImageTrayOffsetDesktop})`;
